import React, { Fragment, useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Input } from "reactstrap";
import Select from "react-select";
import { IoMdPaperPlane } from "react-icons/io";
import { ClipLoader } from "react-spinners";
import { ImCancelCircle } from "react-icons/im";
import { SlideAlert } from "../../reusableComponents/Alerts";
import { marked } from "marked";
import axios from "axios";
import Button from "../../reusableComponents/Buttons/Button";
import { useSelector } from "react-redux";
import { useGetAddEditEmailsQuery, useGetEmailsTemplatesQuery } from "../../../redux/api/emailsApi";
import { useSendEmailMutation } from "../../../redux/api/contactsApi";
import { useTranslation } from "react-i18next";
import { TextArea } from "../../reusableComponents/Inputs";
import VisibilityIcon from "@mui/icons-material/Visibility";

const API_URL = "http://192.168.100.155/local/api";
const PREVIEW_URL = "http://192.168.100.155:80/local/media/images";
const UPLOAD_ENDPOINT = "files/images/new";

function SendEmailModal({ show, hide, loading, id, email }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const [textInEditor, setTextInEditor] = useState("");
  const [generatedHTML, setGeneratedHTML] = useState("");
  const [subject, setSubject] = useState("");
  const [myName, setMyName] = useState(user?.firstName);
  const [myEmail, setMyEmail] = useState(user?.email);
  const [sendLoading, setSendLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [editorToolbar, setEditorToolbar] = useState([]);
  const [toolbarLoading, setToolbarLoading] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [prevEmailTemplates, setPrevEmailTemplates] = useState(emailTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [modifiedData, setModifiedData] = useState();

  const { data } = useGetEmailsTemplatesQuery(undefined, { skip: !show });
  const handleFocus = () => {
    if (data) {
      retrieveEmailTemplates();
    }
  };

  const [sendEmailMutation] = useSendEmailMutation();
  useEffect(() => {
    if (data) {
      retrieveEmailTemplates();
    }
  }, [data]);
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    if (isNewTemplate && prevEmailTemplates.length < emailTemplates.length) {
      const newTemplate = emailTemplates.find(
        (template) => !prevEmailTemplates.some((prevTemplate) => prevTemplate.id === template.id)
      );

      if (newTemplate) {
        setTextInEditor(newTemplate.customHtml);
        setSubject(newTemplate.subject);
        setSelectedTemplate(newTemplate);
        setPrevEmailTemplates(emailTemplates);
      }
      setIsNewTemplate(false);
    }
  }, [emailTemplates, isNewTemplate]);

  const retrieveEmailTemplates = async () => {
    let response = data;
    const newTemplates = Object.entries(response?.data).map((x) => x[1]);
    setEmailTemplates(newTemplates);
    setPrevEmailTemplates(newTemplates);
  };

  const createNewOption = {
    id: "create_new",
    name: t("caymland.core.createnew"),
    customHtml: "",
  };

  const emailTemplatesWithOptions = [createNewOption, ...emailTemplates];

  useEffect(() => {
    const htmlText = marked(textInEditor);
    const staticPart = `<!DOCTYPE html><html><head><title></title><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css"></head><body aria-disabled="false" style="min-height: 300px;">`;
    const closingPart = `</body></html>`;
    const htmlString = staticPart + htmlText + closingPart;
    setGeneratedHTML(htmlString);
  }, [textInEditor]);

  const sendEmail = async () => {
    const body = {
      from: myEmail,
      fromName: myName,
      subject: subject,
      body: generatedHTML,
    };
    try {
      setSendLoading(true);
      const response = await sendEmailMutation({ id, body });
      if (!response.error) {
        setMessage("Email sent successfully!");
        setSendSuccess(true);
        setSendLoading(false);
        setSubject("");
        setTextInEditor("");
        setSelectedTemplate(null);
        setTimeout(() => {
          setSendSuccess(false);
        }, 7000);
      }
    } catch (e) {
      setSendLoading(false);
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            axios
              .post(`${API_URL}/${UPLOAD_ENDPOINT}`, body, {
                headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  Authorization: "Basic Q2F5bWxhbmQ6VWx0cmFzb2Z0",
                },
              })
              .then((res) => {
                resolve({
                  default: `${PREVIEW_URL}/${res.data.file.name}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const closeModal = () => {
    setSubject("");
    setTextInEditor("");
    setSelectedTemplate(null);
    hide();
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.language}</span>
    </div>
  );

  const groupedByLanguage = emailTemplatesWithOptions.reduce((acc, template) => {
    const language = template.language || " "; // Replace this with the actual language property
    acc[language] = acc[language] || { language, options: [] };
    acc[language].options.push(template);
    return acc;
  }, {});
  
  // Convert to an array and optionally sort by language if needed
  const groupedAndSortedTemplates = Object.values(groupedByLanguage).sort((a, b) => {
    if (a.language === " ") return -1;
    if (b.language === " ") return 1;
    return a.language.localeCompare(b.language);
  });
  return (
    <Fragment>
      <SlideAlert message={message} show={sendSuccess} setShowAlert={setSendSuccess} />
      <Modal backdrop="static" keyboard={false} show={show} onHide={closeModal} size="lg">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              padding: "4px",
            }}
          >
            {t("caymland.lead.email.send_email.header", { email: email })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col items-center" style={{ marginTop: "1em" }}>
          <Form
            style={{
              width: "90%",
            }}
          >
            {/* <Form.Group style={{ marginBottom: "1em", justifySelf: "center" }}>
              <Form.Label style={{ padingBottom: 0 }}>{t("caymland.email.from_name")}</Form.Label>
              <Input type="text" value={myName} onChange={(e) => setMyName(e.target.value)} />
            </Form.Group>

            <Form.Group style={{ marginBottom: "1em" }}>
              <Form.Label>{t("caymland.mailbox.list.table.field.from")}</Form.Label>
              <Input type="text" value={myEmail} onChange={(e) => setMyEmail(e.target.value)} />
            </Form.Group>

            <Form.Group style={{ marginBottom: "1em" }}>
              <Form.Label>{t("caymland.mailbox.list.table.field.subject")}</Form.Label>
              <Input type="text" onChange={(e) => setSubject(e.target.value)} value={subject} />
            </Form.Group>

            <Form.Group style={{ marginBottom: "1em" }}>
              <Form.Label>
                {t("Body")} <span className="required"></span>
              </Form.Label>
              <TextArea
                placeholder={"caymland.core.typesomething"}
                value={modifiedData?.text ?? ""}
                showSmallEditor={false}
                onChange={(data) => {
                  setModifiedData((prev) => ({
                    ...prev,
                    text: data,
                  }));
                }}
              />
            </Form.Group> */}
            <Form.Group style={{ marginBottom: "1em" }}>
              <Form.Label>{t("caymland.lead.email.template")}</Form.Label>
              <Select
                isSearchable
                isClearable
                loading={loading}
                options={groupedAndSortedTemplates}
                value={selectedTemplate}
                getOptionLabel={(e) => e?.name}
                getOptionValue={(e) => e?.id}
                formatGroupLabel={formatGroupLabel}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    if (selectedOption.id === "create_new") {
                      setIsNewTemplate(true);
                      window.open("/emails/new?template", "_blank", "width=1200,height=600");
                    } else {
                      setTextInEditor(selectedOption.customHtml);
                      setSubject(selectedOption.subject);
                      setSelectedTemplate(selectedOption);
                    }
                  } else {
                    setTextInEditor("");
                    setSubject("");
                    setSelectedTemplate(null);
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            buttons={[
              {
                name: "Close",
                title: t("caymland.core.form.cancel"),
                icon: <ImCancelCircle size={16} />,
                onClick: () => closeModal(),
              },
              {
                name: "Preview",
                title: t("caymland.core.preview") + " " + "E-Mail",
                icon: <VisibilityIcon size={15} />,
                not_active: !selectedTemplate?.id,
                onClick: () => {
                  window.open(`/email/preview/${selectedTemplate?.id}`, "_blank");
                },
                show: false,
              },
              {
                name: "Send",
                title: t("caymland.email.send"),
                icon: sendLoading ? <ClipLoader size={16} /> : <IoMdPaperPlane size={16} />,
                onClick: () => sendEmail(),
              },
            ]}
          />
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default SendEmailModal;
